import React from 'react';
import { Layout } from '@talves/gatsby-theme-site-layout';

// Components
import {
  PostsWithData,
  PostsWithDataSearch,
} from '../../components/Posts/data';
const Posts = ({ pageContext = {} }) => {
  return (
    <Layout header={pageContext.title}>
      {pageContext.search ? <PostsWithDataSearch /> : <PostsWithData />}
    </Layout>
  );
};

export default Posts;
